<script>
import SwiperCore, { Thumbs, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import Swal from "sweetalert2";



SwiperCore.use([Thumbs, Navigation, Pagination]);

import Layout from "./../layouts/main";
import appConfig from "../../app.config";

import {authComputed} from "@/state/helpers";
import moment from "moment/moment";

export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      thumbsSwiper: null,
    };
  },
  computed: {
    ...authComputed,
  },
  components: {
    Layout,
  },
  methods: {
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    confirm() {
      Swal.fire({
        title: this.$t("t-delete-confirm-question"),
        text: this.$t("t-delete-account-info"),
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: this.$t("t-delete-confirm"),
        cancelButtonText: this.$t("t-cancel-button"),
      }).then((result) => {
        if (result.value) {
          Swal.fire(this.$t("t-delete-deleted"), this.$t("t-delete-account-deleted-message"), "success");
        }
      });
    },
  }
};

</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div>
          <!-- Tab panes -->
          <div class="tab-content pt-4 text-muted">
            <div class="tab-pane active" id="overview-tab" role="tabpanel">
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title mb-0">{{ $t("t-profile-personal-data") }}</h5>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr><th class="ps-0" scope="row">{{$t("t-profile-username")}}:</th><td class="text-muted">{{currentUser.username}}</td></tr>
                          <tr><th class="ps-0" scope="row">{{$t("t-profile-email")}}:</th><td class="text-muted">{{currentUser.email}}</td></tr>
                          <tr><th class="ps-0" scope="row">{{$t("t-profile-joining-date")}}:</th><td class="text-muted">{{ dateTime(currentUser.created_at) }}</td></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- end card body -->
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title mb-0">{{ $t("t-subscription-plan") }}</h5>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr><th class="ps-0" scope="row">{{ $t("t-profile-subscription-till") }}:</th><td class="text-muted">{{ currentUser.subscription_period_end ?? '&#45;&#45;&#45;&#45;' }}</td></tr>
                          <tr><th colspan="2" class="ps-0 left" scope="row">
                            <router-link to="/subscription-plan" class="btn btn-success">
                              <i class="ri-lock-password-line align-bottom"></i>
                              {{ $t("t-profile-extend-subscription") }}
                            </router-link>
                          </th></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- end card body -->
                  </div>
                </div>
                  <div class="col-md-6 col-xs-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title mb-0">{{ $t("t-profile-security") }}</h5>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr>
                            <td class="text-muted">
                              <router-link to="/profile/change-password" class="btn btn-success">
                                <i class="ri-lock-password-line align-bottom"></i>
                                {{ $t("t-change-password-button") }}
                              </router-link>
                            </td>
                            <td class="text-muted">
                              <button class="btn btn-danger" id="sa-warning" @click="confirm">
                                <i class="ri-delete-bin-5-line align-bottom"></i>
                                {{ $t("t-delete-account-button") }}
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- end card body -->
                  </div>
                  <!-- end card -->

                </div>

                <!--end col-->
                <div class="col-xxl-9">
                    <div class="card" style="display: none">
                      <div class="card-header">
                        <h5 class="card-title mb-0">{{ $t("t-profile-settings") }}</h5>
                      </div>
                      <div class="card-body p-4">
                        <div class="tab-content">
                          <div class="tab-pane active" id="profileSettings" role="tabpanel">
<!--                              <profile-settings-form></profile-settings-form>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end tab-content-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
